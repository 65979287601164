<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <HelloWorld msg="Welcome to Your Vue.js App" />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #b11b1d;
  margin-top: 60px;
}

#home {
  margin-top: 300px;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #557790;
  margin-left: 5px;
  margin-right: 5px;
  flex-direction: row;
  justify-content: space-evenly;
}

#home button {
  width: 30%;
  height: 80px;
  font-size: 20px;
}

#home {
  width: 750px;
  font-size: 50px;
}

#view {
  margin-top: 10px;
}

.file {
  width: 100px;
  height: 100px;
}

#stl_div {
  margin-top: 100px;
  width: 1000px;
  height: 100%;
  margin-left: 50px;
}

#stl_div h2 {
  margin-top: 100px;
  width: 250px;
  height: 100%;
  margin-left: 50px;
}

#stl_div #input_file {
  width: 250px;
  height: 100%;
  margin-left: -500px;
}

#nrrd_div {
  margin-top: 100px;
  width: 1000px;
  height: 100%;
  margin-left: 50px;
}

#nrrd_div h2 {
  margin-top: 100px;
  width: 250px;
  height: 100%;
  margin-left: 50px;
}

#nrrd_div #filePicker {
  width: 250px;
  height: 100%;
  margin-left: -500px;
}
#slice h2{
  margin-top: 100px;
  width: 250px;
  height: 100%;
  margin-left: 50px;
}

#slice #input_slice {
  width: 250px;
  height: 100%;
  margin-left: -500px;
}

#slice {
  margin-top: 100px;
  width: 1000px;
  height: 100%;
  margin-left: 50px;
}


</style>
