<template>
    <div id="stl_div">
    <h2>请选择STL文件</h2>
        <input id="input_file" type="file" name="file" @change="handleFile($event)" />
  </div>


</template>

<script>
import '@kitware/vtk.js/favicon';

// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/Profiles/Geometry';

import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkSTLReader from '@kitware/vtk.js/IO/Geometry/STLReader';
export default {
    name: "HelloWorld",
    data(){
        return {
            actor:null,
            reader:vtkSTLReader.newInstance(),
        }
    },  
    created() {
        this.init()

    },
    methods: {
        init() {
            const file = document.getElementById("input_file")
            console.log(file)
            const mapper = vtkMapper.newInstance({ scalarVisibility: false });
            this.actor = vtkActor.newInstance();
            this.actor.setMapper(mapper);
            mapper.setInputConnection(this.reader.getOutputPort());
        },
            update() {
                const style = {
                    padding: "10px",
                    position: "absolute",
                    top: "20px",
                    right: "50px",
                    width: "60%",
                    height: "80%",
                    overflow: "hidden",
                };
                const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({ background: [0, 0, 0], containerStyle: style, });
                const renderer = fullScreenRenderer.getRenderer();
                const renderWindow = fullScreenRenderer.getRenderWindow();
                const resetCamera = renderer.resetCamera;
                const render = renderWindow.render;
                renderer.addActor(this.actor);
                resetCamera();
                render();
            },

        handleFile(event) {
            event.preventDefault();
            // const dataTransfer = event.dataTransfer;
            const files = event.target.files;
            if (files.length === 1) {
                // myContainer.removeChild(fileContainer);
                const fileReader = new FileReader();
                let that = this
                fileReader.onload = function onLoad(e) {
                    console.log(e);
                    console.log(fileReader.result);
                    that.reader.parseAsArrayBuffer(fileReader.result);
                    
                    that.update();
                };
                fileReader.readAsArrayBuffer(files[0]);
            }
        }
    }
}


</script>