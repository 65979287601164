<template>
  <div id="nrrd_div">
    <h2>请选择Dicom文件夹</h2>
    <input type="file" name="file" id="filePicker" @change="imageDatafunction($event)" webkitdirectory />
  </div>
</template>
<script>
import '@kitware/vtk.js/favicon';
import '@kitware/vtk.js/Rendering/Profiles/Volume';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';
import vtkPiecewiseFunction from '@kitware/vtk.js/Common/DataModel/PiecewiseFunction';
import vtkITKHelper from '@kitware/vtk.js/Common/DataModel/ITKHelper';
import vtkVolumeMapper from 'vtk.js/Sources/Rendering/Core/VolumeMapper';
import { readImageDICOMFileSeries } from 'itk-wasm';
import vtkVolume from '@kitware/vtk.js/Rendering/Core/Volume';
import vtkVolumeController from '@kitware/vtk.js/Interaction/UI/VolumeController';
export default {
  name: 'HelloWorld',
  data() {
    return {
      story: document.getElementById("story"),
      renderer: null,
      renderWindow: null,
      fullScreenRenderer: null,
      lookupTable: null,
      actor: null,
      mapper: null,
      reader: null,
      globalDataRange: [0, 255],
      intervalID: null,
      labelContainer: null,
      webWorker: null,
      imageData: null,
      Instances: null,
    }
  },
  created() {
  },
  methods: {
    imageDatafunction(event) {
      let files = event.target.files;
      console.log(files)
      readImageDICOMFileSeries(files).then(({ image }) => {
        this.imageData = vtkITKHelper.convertItkToVtkImage(image);
        console.log(this.imageData)
        const view3d = document.getElementById('nrrd_div');
        const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
          rootContainer: view3d,
          containerStyle: {
            padding: "10px",
            position: "absolute",
            top: "20px",
            right: "50px",
            width: "60%",
            height: "80%",
            overflow: "hidden",
          },
          background: [0, 0, 0],
        });
        const renderer = fullScreenRenderer.getRenderer();
        const renderWindow = fullScreenRenderer.getRenderWindow();
        this.mapper = vtkVolumeMapper.newInstance();
        this.actor = vtkVolume.newInstance();
        renderWindow.getInteractor().setDesiredUpdateRate(15);

        const source = this.imageData;
        console.log(this.imageData)
        // Pipeline handling
        this.actor.setMapper(this.mapper);
        this.mapper.setInputData(source);
        // mapper.setSampleDistance(0.7);

        const sampleDistance =
          0.7 *
          Math.sqrt(
            source
              .getSpacing()
              .map((v) => v * v)
              .reduce((a, b) => a + b, 0),
          );
        this.mapper.setSampleDistance(sampleDistance);

        renderer.addActor(this.actor);

        const lookupTable = vtkColorTransferFunction.newInstance();
        const piecewiseFunction = vtkPiecewiseFunction.newInstance();

        // create color and opacity transfer functions
        // 加了UI之后 这里的设置其实可以删除
        lookupTable.addRGBPoint(200.0, 0.4, 0.2, 0.0);
        lookupTable.addRGBPoint(2000.0, 1.0, 1.0, 1.0);

        piecewiseFunction.addPoint(200.0, 0.0);
        piecewiseFunction.addPoint(1200.0, 0.5);
        piecewiseFunction.addPoint(3000.0, 0.8);

        this.actor.getProperty().setRGBTransferFunction(0, lookupTable);
        this.actor.getProperty().setScalarOpacity(0, piecewiseFunction);

        this.actor.getProperty().setScalarOpacityUnitDistance(0, 4.5);
        this.actor.getProperty().setInterpolationTypeToLinear();
        this.actor.getProperty().setUseGradientOpacity(0, 1);
        this.actor.getProperty().setGradientOpacityMinimumValue(0, 15);
        this.actor.getProperty().setGradientOpacityMinimumOpacity(0, 0.0);
        this.actor.getProperty().setGradientOpacityMaximumValue(0, 100);
        this.actor.getProperty().setGradientOpacityMaximumOpacity(0, 1.0);
        this.actor.getProperty().setShade(1);
        this.actor.getProperty().setAmbient(0.2);
        this.actor.getProperty().setDiffuse(0.7);
        this.actor.getProperty().setSpecular(0.3);
        this.actor.getProperty().setSpecularPower(8.0);

        // Control UI
        const controllerWidget = vtkVolumeController.newInstance({
        size: [400, 150],
        rescaleColorMap: true,
        containerStyle:{
          top:"50px",
        }
      });
      controllerWidget.setContainer(view3d);
      controllerWidget.setupContent(renderWindow, this.actor, true);

      fullScreenRenderer.setResizeCallback(({ width }) => {
        // 2px padding + 2x1px boder + 5px edge = 14
        if (width > 414) {
          controllerWidget.setSize(400, 150);
        } else {
          controllerWidget.setSize(width - 14, 150);
        }
        controllerWidget.render();
        // fpsMonitor.update();
      });

      // First render
      renderer.resetCamera();
      renderWindow.render();
    });


    // });

  }
}

}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
