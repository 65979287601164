<template>
  <link href="../assets/style.css" rel="stylesheet">
  <div id="home">
    <router-link to="/NrrdPage">LoadDicom</router-link>
    <router-link to="/STLPage">LoadSTL</router-link>
    <router-link to="/SlicePage">ShowSlice</router-link>
  </div>

  <div id="view">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'HelloWorld',
  data() {
    return {
      router: null
    }
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
