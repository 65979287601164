import { createRouter, createWebHashHistory } from "vue-router";
import NrrdPage from "./components/NrrdPage"
import STLPage from "./components/STLPage"
import SlicePage from "./components/SlicePage"

const router = createRouter(
    {
        history:createWebHashHistory(),
        routes:[
            {
                path:'/NrrdPage',
                component:NrrdPage
            },
            {
                path:'/STLPage',
                component:STLPage
            },
            {
                path:'/SlicePage',
                component:SlicePage
            }
        ]
    }
);
export default router;